// navigataion styles here
.otr-nav__menu {
  min-height: calc(100vh - 64px);
  @apply absolute w-full p-6 z-10 overflow-scroll bg-white;
}

.otr-nav__item {
  @apply flex h-full items-center px-4 text-sm font-semibold tracking-tight transition-colors duration-200 ease-in-out hover:text-indigo-500;

  &__panel__container {
    @apply rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden;
  }
}
